// 黑名单
<template>
    <div class="menu">
        <div class="screening">
            <el-collapse v-model="activeNames" @change="handleChange">
                <el-collapse-item title="筛选框" name="1">
                    <div class="screening-out">
                        <el-form :inline="true" class="demo-form-inline">
                            <el-row>
                                <el-col :span="6">
                                    <el-form-item label="手机号">
                                        <el-input v-model="searchFrom.number" placeholder="请输入需要查询的手机号" size="mini"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <!-- <el-form-item label="创建时间">
                                        <el-date-picker
                                            v-model="valueTime"
                                            type="datetimerange"
                                            start-placeholder="开始日期"
                                            end-placeholder="结束日期"
                                            size="mini"
                                            :default-time="defaultTime">
                                        </el-date-picker>
                                    </el-form-item> -->
                                </el-col>
                                <el-col :span="6">
                                    <!-- <el-form-item> -->
                                        <div class="title-buttom">
                                            <el-button class="cancel" size="mini" @click="reset" round>重置</el-button>
                                            <el-button type="primary" size="mini" class="confirmAdd" @click="onSubmit" round>查询</el-button>
                                        </div>
                                    <!-- </el-form-item> -->
                                </el-col>
                            </el-row>
                        </el-form>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </div>
        <el-row>
            <el-col :span="12">
                <div class="title-name">
                    数据列表
                </div>
            </el-col>
            <el-col :span="12">
                <div class="title-buttom">
                    <el-tooltip content="新增黑名单" placement="top" effect="light">
                        <el-button class="editor" size="mini" icon="el-icon-plus" @click="addMenu" circle></el-button>
                    </el-tooltip>
                    <el-tooltip content="刷新列表" placement="top" effect="light">
                        <el-button class="editor" size="mini" icon="el-icon-refresh-right" @click="reset" circle></el-button>
                    </el-tooltip>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <div>
                   <el-table
                    class="eltable"
                    v-loading="loading"
                    ref="multipleTable"
                    :data="tableData"
                    tooltip-effect="dark"
                    style="width: 100%"
                    size="mini"
                    :row-style="{height:'50px'}"
                    :cell-style="{padding:'0px'}"
                    :header-cell-style="{background:'#e0e5ff',color:'#24252F'}"
                    row-key="id"
                    default-expand-all
                    :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                    @selection-change="handleSelectionChange">
                        <el-table-column
                        type="selection"
                        width="55">
                        </el-table-column>
                        <el-table-column
                        label="用户id" v-if="userType == 'system'">
                            <template #default="scope">{{ scope.row.uid == null|| scope.row.uid == ''?'-': scope.row.uid}}</template>
                        </el-table-column>
                        <el-table-column
                        label="号码">
                            <template #default="scope">{{ scope.row.number == null|| scope.row.number == ''?'-': scope.row.number}}</template>
                        </el-table-column>
                        <el-table-column
                        label="创建时间">
                            <template #default="scope">{{ scope.row.createTime == null|| scope.row.createTime == ''?'-': scope.row.createTime}}</template>
                        </el-table-column>
                        <el-table-column label="操作" width="180">
                            <template #default="scope">
                                <el-tooltip content="删除用户" placement="top" effect="light">
                                    <el-button class="deletetable" size="mini" icon="el-icon-delete" round @click="confirmEvent(scope.row)"></el-button>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-col>
        </el-row>
        <div class="pager">
            <div>
                <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                background
                :page-sizes="[10, 20, 30, 40]"
                :page-size="searchFrom.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
                </el-pagination>
            </div>
        </div>
        <!-- 弹窗 -->
        <div class="popup-view">
            <el-form label-position="top" label-width="100px" :model="upform" :rules="rules" ref="upform">
                <el-dialog
                title="添加黑名单"
                v-model="centerDialogVisible"
                width="30%"
                :close-on-click-modal="false"
                :destroy-on-close="true"
                @close='closeDialog'>
                <div class="center-body">
                    <el-row :gutter="20">
                        <el-col :span="24">
                            <el-form-item label="手机号：" prop="number">
                                <!-- <el-input v-model="upform.number"></el-input> -->
                                <el-input
                                    v-model="upform.number"
                                    type="textarea"
                                    placeholder="请输入手机号(换行可多输入)"
                                    :autosize="{ minRows: 1, maxRows: 5 }"
                                />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <template #footer>
                    <el-form-item>
                        <el-button class="cancel" plain @click="resetForm('upform')" round>取消</el-button>
                        <el-button class="confirmAdd" @click="submitForm('upform')" round>添加</el-button>
                    </el-form-item>
                </template>
                </el-dialog>
            </el-form>
        </div>
    </div>
</template>

<script>
import blackNumber from '@/api/open/black/blackNumber'
import { IconFont } from "@/utils/iconfont"
export default {
    components: {
        IconFont,
    },
    data () {
        const checkNumber = (rule, value, callback) => {
            let num = 0,types = null,errorItem;
            if(value == null){
                callback(new Error('请填写号码 (多个号码请换行输入)'))
            }else if(value.length == 0){
                callback(new Error('请填写号码 (多个号码请换行输入)'))
            }else{
                let newArr = []
                newArr = value.split(/[(\r\n)\r\n]+/)
                newArr.forEach((item,i)=>{
                    if(item.match(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9]|9[0-9]|0[0-9][0-9]|2[0-9]|0[0-9][0-9][0-9])\d{8}$/) == null){
                    // if(item.match(/^(1[3|4|5|6|7|8|9])\\d{9}$|^0\\d{2,3}-?\\d{7,8}$|^9\\d{9}/) == null){
                        // callback(new Error('请输入正确的号码 (请检查第'+(i+1)+"行)"))
                        num++
                        types = i
                        errorItem = item
                    }
                })
                if( num != 0 ){
                    callback(new Error('请输入正确的号码 (请检查第'+(types+1)+"行："+ errorItem +")"))
                }else{
                    callback()
                }
            }
        }
        return {
            tableHeight:'',
            searchFrom:{
                page:1,
                pageSize:10,
                number:''
            },
            total:0,
            currentPage: 1,//分页
            multipleSelection: [],//选中集合
            valueTime:'',//选中时间段
            defaultTime: [
                new Date(2000, 1, 1, 12, 0, 0),
                new Date(2000, 2, 1, 8, 0, 0)
            ], //默认时间 '12:00:00', '08:00:00'
            activeNames: [],//折叠面板
            tableData: [],//菜单表
            // loding 状态启用
            loading: true,
            userType:this.$store.state.userType,
            upform:{
                number:null
            },
            centerDialogVisible:false,
            textarea1:'',
            rules: {
                number: [
                    { required: true, validator: checkNumber, trigger: 'blur' },
                ],
            }
        }
    },
    created() {
        this.tableHeight = this.$store.state.tableHeight
    },
    watch: {

    },
    mounted() {
        this.getDataList(true)
    },
    methods: {
        // 查询
        onSubmit() {
           this.getDataList(true)
        },
        // 重置
        reset() {
            this.searchFrom = {
                page: this.currentPage,
                pageSize: 10,
            },
            this.getDataList(true)
        },
        // 获取数据列表
        getDataList(type) {
            this.loading = type
            blackNumber.blackNumberList({
                ...this.searchFrom
            }).then((res) => {
                this.tableData = res.data.records
                this.total = res.data.total
                this.loading = false
            })
        },
        // 分页
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            this.searchFrom.pageSize = val
            this.getDataList(true)
        },
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            this.searchFrom.page = val
            this.currentPage =val
            this.getDataList(true)
        },
        // 折叠面板
        handleChange(val) {
            console.log(val);
        },
        // 多选
        handleSelectionChange(val) {
            this.multipleSelection = val
            // this.multipleSelection = []
            // val.forEach(item => {
            //     this.multipleSelection.push(item.id)
            // });
        },
        // 删除确认
        confirmEvent(row) {
            let idList = []
            this.multipleSelection.forEach(item => {
                idList.push(item.id)
            });
            this.$confirm( this.multipleSelection.length == 0?'将删除该号码, 是否继续?':'将删除选中号码, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                blackNumber.blackNumberDelete({
                    ids:this.multipleSelection.length == 0?[row.id]:idList,
                }).then((res) => {
                    this.$notify({
                        title: '成功',
                        message: '号码删除成功！',
                        type: 'success'
                    });
                    this.getDataList(true)
                })
            }).catch(() => {
                // this.$notify.info({
                //     title: '通知',
                //     message: '已取消删除!'
                // });
            });
        },
        // 新增
        addMenu(){
            this.centerDialogVisible = true
        },
        unique(arr) {
            const res = new Map();
            return arr.filter((a) => !res.has(a) && res.set(a, 1))
        },
        // 表单提交
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let arrLest = []
                    let upArr = []
                    arrLest = this.upform.number.split(/[(\r\n)\r\n]+/)
                    this.unique(arrLest).forEach((item,i) =>{
                        upArr.push({
                            number:item.trim()
                        })
                    })
                    blackNumber.blackNumberAdd({
                        blackNumberDtoList:upArr
                    }).then((res) => {
                        this.$notify({
                            title: '成功',
                            message: '黑名单添加成功！',
                            type: 'success'
                        });
                        this.getDataList(true)
                        this.centerDialogVisible = false
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.centerDialogVisible = false
        },
        closeDialog() {
            this.$refs.upform.resetFields()
            this.centerDialogVisible = false
        },
    }
}
</script>

<style lang="scss" scoped>
.menu{
    height: 100%;
    overflow-y: overlay;
    padding-right: 18px;
    .title-name{
        font-size: .9375rem;
        font-weight: 600;
        color: #303133;
    }
    .top-first{
        .search-box{
            width: 18.75rem;
        }
    }
}
.screening{
    margin-bottom: .9375rem;
}
.el-row {
    margin-bottom: .9375rem;
    &:last-child {
        margin-bottom: 0;
    }
}

.title-buttom{
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
}
.editor{
    color: #0170ED;
    background: #E3F0FF;
}
.center-body{
    margin: 0 1.875rem;
}
.deletetable{
    color: #FF845B;
    background: #FFE4DB;
}
.deletetable-other{
    margin-right: 1.25rem;
}
.confirmAdd{
    background: #637DFF;
    color: #fff;
}
.cancel{
    color: #637DFF;
    border: #637DFF solid 1px;
}
.screening-out{
    margin: 0 .9375rem;
}
.out-icon:hover{
    background: #E3F0FF;
    color: #0170ED;
}
.pager {
    display: flex;
    justify-content: flex-end;
}
</style>

<style lang="scss">
.menu .el-collapse-item__content {
    padding-bottom: 0 !important;
}
.eltable {
    overflow-y: overlay;

}
</style>